.home-wrapper {
    text-align: center;
    font-family: 'Roboto', sans-serif;

    .intro-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;

        .head-wrapper {    
            .head-shot {
                border: 1px transparent black;
                border-radius: 250px;
                margin: 50px 125px 0 0;
                width: 250px;
            }
        }
    
        .intro-wrapper {
            color: #212529;
            width: 700px;
    
            .intro-title {
                font-size: 34px;
                font-weight: 700;
                margin: 75px 0 0 125px;
                max-width: 500px;
            }
    
            .intro-text {
                font-size: 18px;
                font-weight: 300;
                margin: 15px 0 15px 125px;
                width: 560px;
            }
        }
    }

    .projects-title {
        border-bottom: 1px solid #5e5e5e;
        color: #212529;
        font-size: 40px;
        font-weight: 500;
        margin: 100px auto 50px;
        width: 300px;
    }

    .projects-container {
        display: flex;
        justify-content: space-evenly;

        .projects-link {
            background-color: #eeeeee;
            border-radius: 3px;
            color: #212529;
            cursor: pointer;
            flex-grow: 1;
            height: 300px;
            max-width: 500px;
            margin-bottom: 50px;

            .projects-link-text {
                font-size: 30px;
                font-weight: 500;
                margin-top: 120px;
            }

            &:hover {
                box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
                background-color: #333333;
                border: 1px solid #a7a7a7;
                color: #eeeeee;
            }
        }
    }
}
