.node-text {
    cursor: pointer;
    // font-size: 14px;
    text-anchor: middle;
    fill: #000000;
}

.node-circle {
    cursor: pointer;
    stroke: #000;
    stroke-width: .5;
}