.contact-wrapper {
    .contact-header {
        border-bottom: 1px solid #d6d6d6;
        font-family: sans-serif;
        font-size: 50px;
        font-weight: 600;
        margin: 50px auto 20px;
        text-align: center;
        width: 525px;
        word-wrap: break-word;
    }

    .contact-container {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 100%;

        .contact-item {
            font-family: sans-serif;
            font-size: 25px;
            margin: 0 15px 0;
            
            &.clickable {
                cursor: pointer;
            }
        }
    }

    .copy-popup {
        background-color: #e8eaed;
        border-radius: 10px;
        line-height: 30px;
        position: fixed;
        text-align: center;
        transition: opacity 3s ease-in-out;

        height: 30px;
        width: 175px;
    }
}