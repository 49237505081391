.drop-down-button-container {
    background-color: #f8f9fa;
    border: 1px solid #dadce0;
    border-radius: 4px;
    color: #3c4043;
    font-family: arial,sans-serif;
    font-size: 14px;
    margin: 10px auto;
    line-height: 25px;
    height: 25px;
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    
    &:hover {
        box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
        background-color: #f8f9fa;
        border: 1px solid #a7a7a7;
        color: #202124;
    }

    .drop-down-submit {
        background-color: #b9b9b9;
        border-radius: 8px;
        margin: 0 auto;
        width: 50px;

        &:hover {
            box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
            background-color: #999999;
            color: #202124;
        }
    }

    .drop-down-container {
        background-color: #f8f9fa;
        border: 1px solid #dadce0;
        position: absolute;

        height: 75px;
        width: 175px;
        
        .drop-down-input-container {
            display: flex;
            margin: 10px;

            .drop-down-input-title {
                margin-right: 5px;
            }
            
            .drop-down-input-box {
                height: 20px;
                width: 50px;
                border: 1px solid #a7a7a7;
            }
        }

    }
}