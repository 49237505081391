.map-wrapper {
    font-family: arial,sans-serif;
    margin: auto;
    text-align: center;
    height: 100%;
    width: 100%;

    .map {
        margin: auto;
        border: 2px solid black;
        height: 40%;
        width: 40%;
    }

    .add-node-container {
        background-color: #c5c5c5;
        border-radius: 15px;
        cursor: pointer;
        margin: auto;
        position: absolute;
        text-align: center;

        height: 60px;
        width: 100px;
        
        .add-node-title {
            font-weight: 500;
            margin: 5px 0 10px 0;
        }

        .add-node-submit {
            background-color: #9c9c9c;
            border-radius: 5px;
            font-weight: 500;
            margin: 10px auto;

            height: 20px;
            width: 80px;

            &:hover {
                box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
                background-color: #000000;
                border: 1px solid #a7a7a7;
                color: #d6d6d6;
            }
        }
    }

    .edit-node-container {
        background-color: #c5c5c5;
        border-radius: 15px;
        cursor: pointer;
        font-weight: 500;
        margin: auto;
        position: absolute;
        text-align: center;

        height: 115px;
        width: 150px;
        
        .edit-node-title {
            margin: 5px 0 5px 0;
        }

        .add-edge-container {
            .add-edge-title {
                display: inline-block;
                margin: 0px 5px;
                text-align: left;
            }

            .input-box {
                height: 20px;
                width: 60px;
                border: 1px solid transparent;
                border-bottom: 1px solid #e8eaed;
                margin-bottom: 3px;
            }
        }

        .edit-node-submit {
            background-color: #9c9c9c;
            border-radius: 5px;
            font-weight: 500;
            margin: 5px auto;

            height: 20px;
            width: 80px;

            &:hover {
                box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
                background-color: #000000;
                color: #d6d6d6;
            }
        }
    }

    .edit-edge-container {
        background-color: #c5c5c5;
        border-radius: 15px;
        cursor: pointer;
        font-weight: 500;
        margin: auto;
        position: absolute;
        text-align: center;

        height: 60px;
        width: 100px;
        
        .edit-edge-title {
            margin: 5px 0 5px 0;
        }

        .edit-edge-submit {
            background-color: #9c9c9c;
            border-radius: 5px;
            font-weight: 500;
            margin: 5px auto;

            height: 20px;
            width: 80px;

            &:hover {
                box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
                background-color: #000000;
                color: #d6d6d6;
            }
        }
    }

    .algorithm-selector-container {
        display: flex;

        .algorithm-button-submit {
            background-color: #9c9c9c;
            border-radius: 5px;
            cursor: pointer;
            font-weight: 500;
            margin: 3px auto;
            text-align: center;
            user-select: none;
            
            height: 20px;
            width: 100px;
    
            &:hover {
                box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
                background-color: #000000;
                color: #d6d6d6;
            }
        }

        .algorithm-selector-button {
            background-color: #f8f9fa;
            border: 1px solid #dadce0;
            border-radius: 4px;
            color: #3c4043;
            font-size: 14px;
            margin: 10px auto;
            line-height: 25px;
            height: 25px;
            min-width: 100px;
            text-align: center;
            cursor: pointer;
            user-select: none;
            
            &:hover {
                box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
                background-color: #f8f9fa;
                border: 1px solid #a7a7a7;
                color: #202124;
            }
        }
    }

    .topological-error {
        margin-bottom: 10px;
        font-size: 28px;
        color: #ac0000;
    }

    .graph-settings-title {
        border-bottom: 1px solid #212529;
        color: #212529;
        font-size: 28px;
        margin: 10px auto;
        width: 110px;
    }

    .graph-settings-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: auto;

        .graph-settings-item {
            border: 1px solid rgba(0, 0, 0, 0.1);
            color: #212529;
            font-size: 20px;
            margin: 5px 15px 25px;;
        }
    }
}